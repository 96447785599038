import React, { useState, useEffect } from 'react';
import './RegisterSectionComponent.css';

const RegisterSectionComponent = ({ data }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);

  // Load "isDeviceRegistered" from localStorage on component mount
  // useEffect(() => {
  //   const storedFlag = localStorage.getItem('isDeviceRegistered');
  //   if (storedFlag === 'true') {
  //     setIsDeviceRegistered(true);
  //   }
  // }, []);

  const handleRegisterClick = async () => {

    // Check if already registered
    if (isDeviceRegistered) {
      setError('This device is already registered. No further registrations allowed.');
      return;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError('The email address is incorrect.');
      return;
    }

    // Prepare payload
    const payload = {
      email,
      time: new Date().toISOString(),
      device: navigator.userAgent,
      passportId: 'ecg-atxg-1-001'
    };

    try {
      const response = await fetch('https://api.emissa.eu/dpp/emailRegistration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        // If response is not ok, we throw an error to handle it
        throw new Error(`Request failed with status ${response.status}`);
      }

      // If successful, mark device as registered
      // localStorage.setItem('isDeviceRegistered', 'true');
      setIsDeviceRegistered(true);
      setError('');
    } catch (error) {
      // Here you can handle any errors from the request
      // E.g. you could set an error message to display to the user
      setError('Registration failed. Please try again.');
      console.error('Error while registering:', error);
    }
  };

  return (
    <div className="content-section">
      <div className="text-cent-section">
        <h4>{data.title}</h4>
        {data.text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      {!isDeviceRegistered ? (
        <div className="register-section">
          <input
            type="email"
            placeholder="example@domain.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <button onClick={handleRegisterClick} className="register-button">
            Register product
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      ) : (
        // Once registered (or if already registered in a previous visit) show this message.
        <div className="text-cent-section">
          <p className="success-message">
            Registration received!
          </p>
        </div>
      )}
    </div>
  );
};

export default RegisterSectionComponent;
